import { SiTypescript, SiAwslambda, SiJsonwebtokens, SiTailwindcss, SiSocketdotio, SiReact, SiSass, SiDart, SiExpress, SiStyledcomponents, SiPuppeteer, SiGit, SiGithub, SiMongodb, SiPostman } from 'react-icons/si'

export const MainSkiles = [
    {
        name: 'JavaScript/ES6',
        proportion: '85'
    },
    {
        name: 'Node.js',
        proportion: '85'
    },
    {
        name: 'React.js',
        proportion: '80'
    },
    {
        name: 'flutter',
        proportion: '75'
    },
]

export const SkilesData = [
    {
        icon: <SiTypescript />,
        name: 'Type Script'
    },
    {
        icon: <SiAwslambda />,
        name: 'Aws Lambda'
    },
    {
        icon: <SiSocketdotio />,
        name: 'Socketdotio'
    },
    {
        icon: <SiTailwindcss />,
        name: 'Tailwindcss'
    },
    {
        icon: <SiJsonwebtokens />,
        name: 'Jsonwebtokens'
    },
    {
        icon: <SiReact />,
        name: 'React Native'
    },
    {
        icon: <SiSass />,
        name: 'Sass'
    },
    {
        icon: <SiStyledcomponents />,
        name: 'Styled-Components'
    },
    {
        icon: <SiDart />,
        name: 'Dart'
    },
    {
        icon: <SiMongodb />,
        name: 'Mongodb'
    },
    {
        icon: <SiExpress />,
        name: 'Express'
    },
    {
        icon: <SiGit />,
        name: 'Git'
    },
    {
        icon: <SiGithub />,
        name: 'Github'
    },
    {
        icon: <SiPostman />,
        name: 'Postman'
    },
    {
        icon: <SiPuppeteer />,
        name: 'Puppeteer'
    },
]